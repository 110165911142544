import React, { useState, useRef, useEffect } from "react";
import { useBootstrap, useMui, useOther } from "../../hooks/Hooks";
import { CustomDescription, CustomHeading } from "../../utils/Common";
import useCIRPTaskHook from "../../hooks/useCIRPTaskHook";
import { ArrowBack, Delete } from "@mui/icons-material";
import axios from "../../services/axios";

const CaseRunTaskDetails = () => {
  const { Card } = useBootstrap();
  const { Button, TextField, Box, IconButton, Typography, List, ListItem } =
    useMui();
  const { useQuery, useParams, systemDT, useNavigate, Cookies, gobalUrl } =
    useOther();
  const { getCIRPTaskDetailsHook } = useCIRPTaskHook();

  const { task_id } = useParams();
  const navigate = useNavigate();

  // State to handle file uploads and comment
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const [documents, setDocuments] = useState([]);

  // Ref for the hidden input element
  const fileInputRef = useRef(null);

  // Handle file selection and preview
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  // Handle drag events for drag-and-drop file uploading
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    setIsDragOver(false);
  };

  // Handle click on drag-and-drop area to trigger file input
  const handleClick = () => {
    fileInputRef.current.click(); // Programmatically trigger the file input click
  };

  // Handle comment change
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Remove selected file
  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Fetching task details using the task_id
  const { data: taskDetails, isLoading } = useQuery(
    `TaskDetails-${task_id}`,
    () => getCIRPTaskDetailsHook(task_id)
  );

  // useEffect to set the comment and documents when taskDetails is loaded
  useEffect(() => {
    if (taskDetails && taskDetails.length > 0) {
      setComment(taskDetails[0]?.comment || ""); // Set the comment from taskDetails
      const parsedDocuments = JSON.parse(taskDetails[0]?.documents || "[]");
      setDocuments(parsedDocuments); // Set the parsed documents
    }
  }, [taskDetails]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleCompleteTask = async () => {
    try {
      const formData = new FormData();

      // Append existing documents to FormData
      documents.forEach((doc) => {
        formData.append("documents[]", doc);
      });

      // Append new files to FormData
      selectedFiles.forEach((file) => {
        formData.append("documents[]", file);
      });

      // Append other form data
      formData.append("comment", comment);
      formData.append("task_id", task_id);
      formData.append("systemDT", systemDT);
      formData.append("done_by", Cookies.get("user_id"));

      // Make the API request to your backend
      const response = await axios.post(
        "/cirp-task/complete-cirp-task-status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("CIRP Task Completed");
      navigate(-1);

      // Optionally, reset the form state
      setSelectedFiles([]);
      setComment("");
    } catch (error) {
      // Handle error response
      console.error("Error submitting the task details:", error);
      alert(
        "There was an error submitting the task details. Please try again."
      );
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Append files to FormData
      selectedFiles.forEach((file) => {
        formData.append("documents[]", file);
      });

      // Append other form data
      formData.append("comment", comment);
      formData.append("task_id", task_id);
      formData.append("systemDT", systemDT);
      formData.append("done_by", Cookies.get("user_id"));

      // Make the API request to your backend
      const response = await axios.post(
        "/cirp-task/update-cirp-task-status",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert("CIRP task sended for review");
      navigate(-1);
      // Optionally, reset the form state
      setSelectedFiles([]);
      setComment("");
    } catch (error) {
      // Handle error response
      console.error("Error submitting the task details:", error);
      alert(
        "There was an error submitting the task details. Please try again."
      );
    }
  };

  // Helper function to display file preview (for images) or just file name
  const renderFilePreview = (file, index) => {
    if (file.type.startsWith("image/")) {
      return (
        <div key={index} style={{ marginBottom: "10px" }}>
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            style={{ maxHeight: "100px", marginRight: "10px" }}
          />
          <IconButton
            onClick={() => handleRemoveFile(index)}
            size="small"
            color="error"
          >
            <Delete />
          </IconButton>
        </div>
      );
    }
    return (
      <ListItem
        key={index}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Typography>{file.name}</Typography>
        <IconButton
          onClick={() => handleRemoveFile(index)}
          size="small"
          color="error"
        >
          <Delete />
        </IconButton>
      </ListItem>
    );
  };

  return (
    <Card className="custom-card">
      <Card.Header className="d-flex justify-content-between align-items-center p-3">
        <div>
          <CustomHeading
            Heading={"Task ID :- " + taskDetails[0].cirp_task_id}
          />
          <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
            {"Due Date :- " + taskDetails[0].day_of_complete}
          </p>
        </div>
        <Button
          variant="contained"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Card.Header>

      <Card.Body className="p-3 d-flex justify-content-between align-items-center">
        <div>
          <CustomHeading Heading={taskDetails[0].task_label} />
          {taskDetails[0].task_desc && (
            <CustomDescription Description={taskDetails[0].task_desc} />
          )}
        </div>
        <div>
          <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
            {"Completed on" + " " + taskDetails[0].completed_on}
          </p>
          {taskDetails[0].reviewer_username && (
            <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
              {"Reviewed by" + " " + taskDetails[0].reviewer_username}
            </p>
          )}
          <p style={{ color: "#a6a6a6", fontSize: "14px" }} className="m-0">
            {"Completed by" + " " + taskDetails[0].done_by_username}
          </p>
        </div>
      </Card.Body>

      {/* Existing Documents Section */}
      <Card.Body className="p-3">
        <Typography variant="subtitle1">Existing Documents:</Typography>
        <List>
          {documents.map((doc, index) => {
            const fileUrl = `${gobalUrl}/uploads/cirp/task/${doc}`;
            const fileExtension = doc.split(".").pop().toLowerCase();
            const isImage = ["jpg", "jpeg", "png", "gif", "webp"].includes(
              fileExtension
            );

            return (
              <ListItem
                key={index}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {isImage ? (
                  <img
                    src={fileUrl}
                    alt={doc}
                    style={{ maxHeight: "100px", marginRight: "10px" }}
                  />
                ) : (
                  <Typography>
                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                      {doc}
                    </a>
                  </Typography>
                )}
                <IconButton
                  onClick={() => handleRemoveFile(index)} // Assuming you want to remove it
                  size="small"
                  color="error"
                >
                  <Delete />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </Card.Body>

      {/* File upload section with drag-and-drop support */}
      <Card.Body className="p-3">
        <Box
          sx={{
            border: "2px dashed #ccc",
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: isDragOver ? "#f0f0f0" : "#fff",
            textAlign: "center",
            cursor: "pointer",
            height: "100px",
          }}
          onClick={handleClick} // Trigger file input click when clicked
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Typography>
            {isDragOver
              ? "Release to upload files"
              : "Drag and drop files here or click to select files"}
          </Typography>
          <input
            ref={fileInputRef} // Referencing the input
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Box>

        {/* File Preview */}
        {selectedFiles.length > 0 && (
          <div>
            <Typography variant="subtitle1">Selected Files:</Typography>
            <List>
              {selectedFiles.map((file, index) =>
                renderFilePreview(file, index)
              )}
            </List>
          </div>
        )}
      </Card.Body>

      {/* Comment textarea section */}
      <Card.Body className="p-3">
        <TextField
          label="Comment"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={comment}
          onChange={handleCommentChange}
          placeholder="Enter your comment here"
        />
      </Card.Body>

      {/* Submit Button */}
      <Card.Body className="p-3 d-flex justify-content-between align-items-center">
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        {/* {Cookies.get("role") === "master" && ( */}
        <Button variant="contained" onClick={handleCompleteTask}>
          Complete Task
        </Button>
        {/* )} */}
      </Card.Body>
    </Card>
  );
};

export default CaseRunTaskDetails;
