import React, { useState } from "react";
import { useBootstrap, useIcon, useMui, useOther } from "../hooks/Hooks";
import SmallDatatable from "../components/SmallDatatable";
import CustomDialog from "../components/CustomDialog";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import useCIRPStageHook from "../hooks/useCIRPStageHook"; // importing the correct hook

const CIRPStage = () => {
  const { Card } = useBootstrap();
  const { Button } = useMui();
  const { Plus } = useIcon();
  const { useQuery } = useOther();
  const { useAddCIRPStageHook, setAllCIRPStageHook, useDeleteCIRPStageHook } =
    useCIRPStageHook();

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    stage_name: "",
    min_max_day: "",
  });

  const AllCIRPStage = useQuery("AllCIRPStage", () => {
    return setAllCIRPStageHook();
  });

  const { mutate, isLoading } = useAddCIRPStageHook();
  const { mutate: deleteMutate, isLoading: deleteIsLoading } =
    useDeleteCIRPStageHook();

  // Update state as form inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDelete = (data) => {
    deleteMutate(data, {
      onSuccess: () => {
        AllCIRPStage.refetch();
        // You can trigger a reload of the datatable here or show a success message
      },
      onError: (error) => {
        console.error("Error occurred while deleting:", error);
      },
    });
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    mutate(
      { ...formData },
      {
        onSuccess: (data) => {
          AllCIRPStage.refetch();
          setOpen(false);
          // You can trigger a reload of the datatable here or show a success message
        },
        onError: (error) => {
          console.error("Error occurred while submitting the form:", error);
        },
      }
    );
  };

  if (AllCIRPStage.isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Card className="custom-card">
      <Card.Body className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <Card.Title>CIRP Stage</Card.Title>
          <Button
            variant="contained"
            startIcon={<Plus size={18} />}
            className="add-btn"
            onClick={() => setOpen(true)}
          >
            Add Stage
          </Button>
        </div>
        <SmallDatatable
          columns={["stage Id", "Created Date", "Stage Name", "Days"]}
          rows={AllCIRPStage.data}
          onDelete={handleDelete}
        />
        <CustomDialog
          open={open}
          handleDialogClose={() => setOpen(false)}
          dialogTitle="Add CIRP Stage"
        >
          <Box component="form" onSubmit={handleFormSubmit}>
            <TextField
              autoFocus
              required
              name="stage_name"
              label="Stage Name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.stage_name}
              onChange={handleInputChange}
            />
            <TextField
              className="mt-3"
              required
              name="min_max_day"
              label="Stage Days"
              type="text"
              fullWidth
              variant="standard"
              value={formData.min_max_day}
              onChange={handleInputChange}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button type="submit" color="primary" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </Box>
          </Box>
        </CustomDialog>
      </Card.Body>
    </Card>
  );
};

export default CIRPStage;
