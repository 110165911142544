import axios from "../services/axios";
import { useOther } from "./Hooks";

const useAuthHook = () => {
  const { useMutation, systemDT } = useOther();

  const setLoginHook = async (data) => {
    try {
      const response = await axios.post(`/authentication/user-login`, {
        ...data,
        systemDT: systemDT,
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  const setLogoutHook = async (data) => {
    try {
      const response = await axios.post(`/authentication/logout-user`, {
        ...data,
        systemDT: systemDT,
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  const useLoginHook = () => {
    return useMutation(setLoginHook);
  };

  const useLogoutHook = () => {
    return useMutation(setLogoutHook);
  };

  return { useLoginHook, useLogoutHook };
};

export default useAuthHook;
