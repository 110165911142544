import "./../styles/Common.css";

export const CustomHeading = (props) => {
  return (
    <h3 className="custom-heading text-capitalize" {...props}>
      {props.Heading}
    </h3>
  );
};

export const CustomDescription = (props) => {
  return (
    <p className="custom-description" {...props}>
      <span>{props.startIcon}</span> {props.Description}
    </p>
  );
};
