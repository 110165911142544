import axios from "../../services/axios";
import { useOther } from "../Hooks";

const useCaseSummaryHook = () => {
  const { useMutation, systemDT } = useOther();

  const getCirpCaseParticipantsHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-meeting/get-cirp-case-participants`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  const setAddCaseParticipantsHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-meeting/add-cirp-case-participants`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };
  
  const setEditCaseParticipantsHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-meeting/edit-cirp-case-participants`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  const setDeleteCaseParticipantsHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-meeting/delete-cirp-case-participants`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useEditCaseParticipantsHook = () => {
    return useMutation(setEditCaseParticipantsHook); // return the mutation hook
  };

  // Hook that uses mutation to call the POST request function
  const useDeleteCaseParticipantsHook = () => {
    return useMutation(setDeleteCaseParticipantsHook); // return the mutation hook
  };

  // Hook that uses mutation to call the POST request function
  const useAddCaseParticipantsHook = () => {
    return useMutation(setAddCaseParticipantsHook); // return the mutation hook
  };

  return {
    getCirpCaseParticipantsHook,
    useEditCaseParticipantsHook,
    useDeleteCaseParticipantsHook,
    useAddCaseParticipantsHook,
  };
};

export default useCaseSummaryHook;
