import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import CIRPHeader from "../components/CIRPHeader";
import EditIcon from '@mui/icons-material/Edit'; // For the edit button icon

const CirpViewClaim = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // State to manage dialog visibility

  const { row } = location.state || {};

  useEffect(() => {
    if (!row) {
      navigate('/some-fallback-path');
    }
  }, [row, navigate]);

  const BasicDetails = [
    { title: "Claimant Name", description: row?.claim_name, field: "claim_name" },
    { title: "Persona", description: row?.claim_type, field: "claim_type" },
    { title: "Principal Amount Claimed", description: row?.principal_amount_claim, field: "principal_amount_claim" },
    { title: "Interest Amount Claimed", description: row?.interest_amount_claim, field: "interest_amount_claim" },
    { title: "Date Received", description: row?.claim_date, field: "claim_date" },
    { title: "Claim Source", description: row?.source, field: "source" },
    { title: "Status", description: row?.status, field: "status" },
  ];

  const ClaimAmountDetails = [
    { title: "Principal Amount Claimed", description: row?.principal_amount_claim, field: "principal_amount_claim" },
    { title: "Interest Amount Claimed", description: row?.interest_amount_claim, field: "interest_amount_claim" },
    { title: "Total Amount Claimed", description: (row?.principal_amount_claim || 0) + (row?.interest_amount_claim || 0) },
  ];

  const BankDetails = [
    { title: "Name of the Beneficiary", field: "beneficiary_name", description: row?.beneficiary_name },
    { title: "Name of the Bank", field: "bank_name", description: row?.bank_name },
    { title: "Type of Account", field: "account_type", description: row?.account_type },
    { title: "Account Number", field: "account_number", description: row?.account_number },
    { title: "Branch", field: "branch", description: row?.branch },
    { title: "IFSC Code", field: "ifsc_code", description: row?.ifsc_code },
  ];

  const handleEdit = () => {
    setOpen(true); // Open the form dialog on edit
  };

  const handleClose = () => {
    setOpen(false); // Close the form dialog
  };

  const handleSubmit = (values) => {
    console.log("Updated Values:", values);
    handleClose();
  };

  return (
    <Card className="custom-card mt-3 p-3" style={{ borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)", backgroundColor: "#f8f9fa" }}>
      <Card.Header>
        <CIRPHeader />
      </Card.Header>

      <Card.Body>
        <div className='m-3 d-flex justify-content-end'>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEdit}
            style={{
              borderColor: '#7367f0',
              color: '#7367f0',
              textTransform: 'none',
              fontWeight: '500',
              padding: '6px 16px',
              borderRadius: '8px',
            }}
          >
            Edit Details
          </Button>
        </div>

        {/* Accordion for General Details */}
        <Accordion className="mt-3" style={{ borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#7367f0', color: '#ffffff', border:'none' }}>
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>General Details</h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {BasicDetails.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4 style={{ fontSize: "14px", fontWeight: "500", color: "#6b7280" }}>
                    {detail.title}
                  </h4>
                  <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>
                    {detail.description || 'N/A'}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Claim Amount Details */}
        <Accordion className="mt-3" style={{ borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#7367f0', color: '#ffffff' }}>
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>Claim Amount Details</h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {ClaimAmountDetails.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4 style={{ fontSize: "14px", fontWeight: "500", color: "#6b7280" }}>
                    {detail.title}
                  </h4>
                  <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>
                    {detail.description || 'N/A'}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Bank Details */}
        <Accordion className="mt-3" style={{ borderRadius: "8px", backgroundColor: "#ffffff", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: '#7367f0', color: '#ffffff' }}>
            <h5 style={{ fontSize: "18px", fontWeight: "600", margin: "0" }}>Bank Details</h5>
          </AccordionSummary>
          <AccordionDetails>
            <Row>
              {BankDetails.map((detail, index) => (
                <Col key={index} md={4} sm={6} className="px-5 py-3">
                  <h4 style={{ fontSize: "14px", fontWeight: "500", color: "#6b7280" }}>
                    {detail.title}
                  </h4>
                  <p style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}>
                    {detail.description || 'N/A'}
                  </p>
                </Col>
              ))}
            </Row>
          </AccordionDetails>
        </Accordion>
      </Card.Body>

      {/* Formik Dialog for editing details */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Details</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ ...row }}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <h4>General Details</h4>
                <Row>
                  {BasicDetails.map((detail, index) => (
                    <Col key={index} md={6} className="mb-3">
                      <label style={{ fontWeight: '600', color: '#333' }}>{detail.title}</label>
                      <Field name={detail.field} className="form-control" style={{ borderRadius: '4px', border: '1px solid #ced4da' }} />
                    </Col>
                  ))}
                </Row>

                <h4>Bank Details</h4>
                <Row>
                  {BankDetails.map((detail, index) => (
                    <Col key={index} md={6} className="mb-3">
                      <label style={{ fontWeight: '600', color: '#333' }}>{detail.title}</label>
                      <Field name={detail.field} className="form-control" style={{ borderRadius: '4px', border: '1px solid #ced4da' }} />
                    </Col>
                  ))}
                </Row>

                <DialogActions>
                  <Button onClick={handleClose} color="secondary">Cancel</Button>
                  <Button type="submit" color="primary">Save</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default CirpViewClaim;
