import { BriefcaseBusiness, CalendarMinus2, ChevronLeft, FilePenLine, ImagePlay, LayoutList, Minus, UserPlus, X } from 'lucide-react';
import { Calendar } from 'lucide-react';
import { LayoutDashboard } from 'lucide-react';
import { Users } from 'lucide-react';
import { PcCase } from 'lucide-react';
import { CircleHelp } from 'lucide-react';
import { Share2 } from 'lucide-react';
import { Trash2 } from 'lucide-react';
import { ChevronRight } from 'lucide-react';
import { CalendarCheck2 } from 'lucide-react';
import { ShieldX } from 'lucide-react';
import { Bookmark } from 'lucide-react';
import { Bell } from 'lucide-react';
import { Plus } from 'lucide-react';
import { Pencil } from 'lucide-react';
import { Power } from 'lucide-react';
import { MailCheck } from 'lucide-react';
import { Settings } from 'lucide-react';
import { Dot } from 'lucide-react';
import { ScrollText } from 'lucide-react';

import { IconContext } from "./Context";

const Icon = ({ children }) => {
  return (
    <IconContext.Provider
      value={{
        Dot,
        CalendarMinus2,
        ScrollText,
        Settings,
        BriefcaseBusiness,
        UserPlus,
        ImagePlay,
        Minus,
        Calendar,
        Plus,
        LayoutDashboard,
        Users,
        PcCase,
        CalendarCheck2,
        CircleHelp,
        Share2,
        Trash2,
        ChevronLeft,
        ChevronRight,
        Pencil,
        ShieldX,
        Bookmark,
        Bell,
        Power,
        MailCheck,
        FilePenLine,
        LayoutList,
        X
      }}
    >
      {children}
    </IconContext.Provider>
  );
};

export default Icon;
