import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import { BootstrapContext } from "./Context";

const Bootstrap = ({ children }) => {
  return (
    <BootstrapContext.Provider
      value={{
        Container,
        Row,
        Card,
        Col,
        Modal,
        Alert,
        Badge
      }}
    >
      {children}
    </BootstrapContext.Provider>
  );
};

export default Bootstrap;
