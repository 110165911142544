import React, { useState } from "react";
import { useBootstrap, useIcon, useMui, useOther } from "../hooks/Hooks";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import SmallDatatable from "../components/SmallDatatable";
import CustomDialog from "../components/CustomDialog";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import useCIRPMilestoneHook from "../hooks/useCIRPMilestoneHook"; // importing the correct hook
import useCIRPStageHook from "../hooks/useCIRPStageHook";

const CIRPMilestone = () => {
  const { Card } = useBootstrap();
  const { Button } = useMui();
  const { Plus } = useIcon();
  const { useQuery } = useOther();
  const {
    useAddCIRPMilestoneHook,
    setAllCIRPMilestoneHook,
    useDeleteCIRPMilestoneHook,
  } = useCIRPMilestoneHook();

  const { setAllCIRPStageHook } = useCIRPStageHook();

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    stage: "",
    milestone: "",
  });

  const AllCIRPStage = useQuery("AllCIRPStage", () => {
    return setAllCIRPStageHook();
  });

  const AllCIRPMilestone = useQuery("AllCIRPMilestone", () => {
    return setAllCIRPMilestoneHook();
  });

  const { mutate, isLoading } = useAddCIRPMilestoneHook();
  const { mutate: deleteMutate, isLoading: deleteIsLoading } =
    useDeleteCIRPMilestoneHook();

  // Update state as form inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDelete = (data) => {
    deleteMutate(data, {
      onSuccess: () => {
        AllCIRPMilestone.refetch();
        // You can trigger a reload of the datatable here or show a success message
      },
      onError: (error) => {
        console.error("Error occurred while deleting:", error);
      },
    });
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    mutate(
      { ...formData },
      {
        onSuccess: (data) => {
          AllCIRPMilestone.refetch();
          setOpen(false);
          // You can trigger a reload of the datatable here or show a success message
        },
        onError: (error) => {
          console.error("Error occurred while submitting the form:", error);
        },
      }
    );
  };

  if (AllCIRPMilestone.isLoading || AllCIRPStage.isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Card className="custom-card">
      <Card.Body className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <Card.Title>CIRP Milestone</Card.Title>
          <Button
            variant="contained"
            startIcon={<Plus size={18} />}
            className="add-btn"
            onClick={() => setOpen(true)}
          >
            Add Milestone
          </Button>
        </div>
        <SmallDatatable
          columns={["Milestone Id", "Created Date", "Stage Name", "Milestone"]}
          rows={AllCIRPMilestone.data}
          onDelete={handleDelete}
        />
        <CustomDialog
          open={open}
          handleDialogClose={() => setOpen(false)}
          dialogTitle="Add CIRP Milestone"
        >
          <Box component="form" onSubmit={handleFormSubmit}>
            <FormControl variant="standard" className="w-100">
              <InputLabel id="demo-simple-select-standard-label">
                Select Stage
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                name="stage"
                value={formData.stage}
                onChange={handleInputChange}
                label="Select Stage"
              >
                {AllCIRPStage.data.map((row) => (
                  <MenuItem value={row.stage_id}>{row.stage_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className="mt-3"
              required
              name="milestone"
              label="Enter Milestone name"
              type="text"
              fullWidth
              variant="standard"
              value={formData.milestone}
              onChange={handleInputChange}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button type="submit" color="primary" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </Box>
          </Box>
        </CustomDialog>
      </Card.Body>
    </Card>
  );
};

export default CIRPMilestone;
