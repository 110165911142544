import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer'
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Visibility } from '@mui/icons-material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

import { MuiContext } from "./Context";
import { Chip, Stack } from '@mui/material';

const Mui = ({ children }) => {
  return (
    <MuiContext.Provider
      value={{
        Accordion,
        AccordionSummary,
        AccordionDetails,
        AccordionActions,
        ExpandMoreIcon,
        Stack,
        Chip,
        Divider,
        Modal,
        Menu,
        Box,
        MenuItem,
        CssBaseline,
        Drawer,
        Badge,
        Tabs,
        Tab,
        AppBar,
        Typography,
        Toolbar,
        PropTypes,
        List,
        ListItem,
        ListItemButton,
        ListItemIcon,
        ListItemText,
        ListItemAvatar,
        Avatar,
        TextField,
        Container,
        FormControlLabel,
        Checkbox,
        Tooltip,
        IconButton,
        MenuIcon,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Button,
        Paper,
        Skeleton,
        Visibility,
      }}
    >
      {children}
    </MuiContext.Provider>
  );
};

export default Mui;
