import React from "react";
import { useBootstrap, useIcon, useMui, useOther } from "../hooks/Hooks";
import useCIRPHook from "../hooks/useCIRPHook";
import FormUtils from "../utils/FormUtils";

const CIRPProcess = ({ caseID }) => {
  const { Card, Row, Col } = useBootstrap();
  const { Button } = useMui();
  const { FilePenLine, ChevronLeft } = useIcon();
  const { useQuery, Formik, Form, dayjs } = useOther();

  const { CustomInputField, CustomFormGroup } = FormUtils();
  const { getCIRPDetails, useUpdateCIRPProcessHook } = useCIRPHook();

  const [editDetails, setEditDetails] = React.useState(false);

  const CirpProcessDetails = useQuery("CIRPProcessDetails", () => {
    return getCIRPDetails(caseID);
  });

  const { mutate, isLoading } = useUpdateCIRPProcessHook();

  if (CirpProcessDetails.isLoading) {
    return <div>Loading...</div>;
  }

  const handleSubmit = (values) => {
    mutate(values, {
      onSuccess: (data) => {
        alert("CIRP Process updated successfully");
        setEditDetails(false);
        CirpProcessDetails.refetch();
      },
      onError: (error) => {
        console.error("Error submitting form: ", error);
        alert("There was an error submitting the form");
      },
    });
  };

  const CirpProcessArr = [
    {
      title: "APPLICANT NAME",
      details: CirpProcessDetails.data[0]?.applicant_name,
    },
    {
      title: "APPLICANT EMAIL",
      details: CirpProcessDetails.data[0]?.applicant_email,
    },
    {
      title: "CIN No.",
      details: CirpProcessDetails.data[0]?.cin_no,
    },
    {
      title: "CORPORATE DEBTOR",
      details: CirpProcessDetails.data[0]?.cd_name,
    },
    {
      title: "STAGE",
      details: CirpProcessDetails.data[0]?.stage,
    },
    {
      title: "NCLT ORDER NO.",
      details: CirpProcessDetails.data[0]?.nclt_order_no,
    },
    {
      title: "APPLICATION FILING DATE",
      details: CirpProcessDetails.data[0]?.application_filing_date,
    },
    {
      title: "RECEIPT DATE",
      details: CirpProcessDetails.data[0]?.receipt_date,
    },
    {
      title: "DEFAULT AMOUNT",
      details: CirpProcessDetails.data[0]?.default_amount,
    },
  ];

  return (
    <Card className="custom-card">
      <Card.Body className="p-3">
        <Button
          className="m-2"
          style={{ float: "right" }}
          startIcon={
            editDetails ? <ChevronLeft size={20} /> : <FilePenLine size={20} />
          }
          onClick={() => setEditDetails(!editDetails)}
        >
          {editDetails ? "Cancel" : "Edit"}
        </Button>
        {editDetails ? (
          <Formik
            initialValues={{
              cd_name: CirpProcessDetails.data[0]?.cd_name,
              cin_no: CirpProcessDetails.data[0]?.cin_no,
              applicant_name: CirpProcessDetails.data[0]?.applicant_name,
              applicant_email: CirpProcessDetails.data[0]?.applicant_email,
              stage: CirpProcessDetails.data[0]?.stage,
              nclt_order_no: CirpProcessDetails.data[0]?.nclt_order_no,
              receipt_date: dayjs(
                CirpProcessDetails.data[0]?.receipt_date
              ).format("YYYY-MM-DD"),
              application_filing_date: dayjs(
                CirpProcessDetails.data[0]?.application_filing_date
              ).format("YYYY-MM-DD"),
              default_amount: CirpProcessDetails.data[0]?.default_amount,
              cirp_id: caseID,
            }}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Row>
                  <CustomFormGroup
                    formlabel="Corporate Debtor Name"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="cd_name"
                        placeholder="Enter Corporate Debtor Name"
                        error={touched.cd_name && !!errors.cd_name}
                        helperText={touched.cd_name && errors.cd_name}
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="CIN No."
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="cin_no"
                        placeholder="Enter CIN No."
                        error={touched.cin_no && !!errors.cin_no}
                        helperText={touched.cin_no && errors.cin_no}
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Applicant Name"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="applicant_name"
                        placeholder="Enter Applicant Name"
                        error={
                          touched.applicant_name && !!errors.applicant_name
                        }
                        helperText={
                          touched.applicant_name && errors.applicant_name
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Applicant Email"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="email"
                        name="applicant_email"
                        placeholder="Enter Applicant Email"
                        error={
                          touched.applicant_email && !!errors.applicant_email
                        }
                        helperText={
                          touched.applicant_email && errors.applicant_email
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="NCLT Order No"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="nclt_order_no"
                        placeholder="Enter NCLT Order No."
                        error={touched.nclt_order_no && !!errors.nclt_order_no}
                        helperText={
                          touched.nclt_order_no && errors.nclt_order_no
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Stage"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="text"
                        name="stage"
                        placeholder="Enter Stage"
                        error={touched.stage && !!errors.stage}
                        helperText={touched.stage && errors.stage}
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Default Amount"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="number"
                        name="default_amount"
                        placeholder="Enter Default Amount"
                        error={
                          touched.default_amount && !!errors.default_amount
                        }
                        helperText={
                          touched.default_amount && errors.default_amount
                        }
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Receipt Date"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="date"
                        name="receipt_date"
                        error={touched.receipt_date && !!errors.receipt_date}
                        helperText={touched.receipt_date && errors.receipt_date}
                      />
                    }
                  />
                  <CustomFormGroup
                    formlabel="Application Filing Date"
                    star="*"
                    FormField={
                      <CustomInputField
                        type="date"
                        name="application_filing_date"
                        error={
                          touched.application_filing_date &&
                          !!errors.application_filing_date
                        }
                        helperText={
                          touched.application_filing_date &&
                          errors.application_filing_date
                        }
                      />
                    }
                  />
                </Row>
                <Button type="submit" variant="contained">
                  Update
                </Button>
              </Form>
            )}
          </Formik>
        ) : (
          <Row>
            {CirpProcessArr.map((detail, index) => (
              <Col key={index} md={4} sm={4} className="px-5 py-3">
                <h4
                  className="m-0"
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#6b7280",
                  }}
                >
                  {detail.title}
                </h4>
                <p
                  className="m-0"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#333333",
                  }}
                >
                  {detail.details}
                </p>
              </Col>
            ))}
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default CIRPProcess;
