import { Field, Formik, Form, ErrorMessage } from "formik";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import React from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import Cookies from "js-cookie";

import { OtherContext } from "./Context";
import { useQuery, useQueryClient } from "react-query";
// import dayjs from "dayjs";

const Other = ({ children }) => {

    // const navigate = useNavigate();
    const gobalUrl = "https://lawyerbackend.multiplerevolution.online";
    // const gobalUrl = "http://localhost:3003";
    const systemDT = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const cookiesDomain = window.location.hostname;
    // const cookiesDomain = "lawyercrm.multiplerevolution.online";

    const setCookie = (name, value) => {
        Cookies.set(name, value, {
            domain: window.location.hostname,
            path: "/",
            expires: 365,
        });
    };

    return (
        <OtherContext.Provider
            value={{
                gobalUrl,
                systemDT,
                setCookie,
                dayjs,
                useQuery,
                useMutation,
                useQueryClient,
                Field,
                Formik,
                Form,
                ErrorMessage,
                Yup,
                NavLink,
                useNavigate,
                useParams,
                Cookies,
                cookiesDomain
            }}
        >
            {children}
        </OtherContext.Provider>
    );
};

export default Other;
