import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

const SmallDatatable = ({ columns, rows, onDelete }) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none", mt: 2 }}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} sx={{ fontWeight: "bold" }}>
                {column}
              </TableCell>
            ))}
            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>{" "}
            {/* New Action Column */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.values(row).map((value, cellIndex) => (
                <TableCell key={cellIndex}>{value}</TableCell>
              ))}
              {/* Action Buttons */}
              <TableCell>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => onDelete(row)} // Assuming each row has an `id` field
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SmallDatatable;
