import React from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import CIRPHeader from "../components/CIRPHeader";
import { useOther } from "../hooks/Hooks";
import { Card } from "react-bootstrap";
import { useQuery } from 'react-query';
import { Plus } from "lucide-react";
import useCIRPMilestoneHook from "../hooks/useCIRPMilestoneHook";


const CirpClaim = () => {
  const { id } = useParams();
  const { useNavigate } = useOther();
  const navigate = useNavigate();


  const { getcirpClaim } = useCIRPMilestoneHook();

  // Fetch claims data using useQuery
  const AllClaim = useQuery('cirpClam', () => getcirpClaim(id));

  // Log loading or data status
  console.log(AllClaim.isLoading ? 'Loading claims...' : AllClaim.data?.data);

  // Columns for the table
  const columns = [
    { header: "CLAIMANT NAME", key: 'claim_name' },
    { header: "PERSONA", key: 'claim_type' },
    { header: "Principal amount claimed", key: 'principal_amount_claim' },
    { header: "Interest amount claimed", key: 'interest_amount_claim' },
    { header: "DATE RECEIVED", key: 'claim_date' },
    { header: "CLAIM SOURCE", key: 'source' },
    { header: "STATUS", key: 'status' },
  ];

  return (
    <Card className="custom-card">
      <Card.Header>
        <CIRPHeader />
      </Card.Header>    

      <Card className="custom-card my-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>Claim</Card.Title>
          <Button
            variant="contained"
            startIcon={<Plus size={18} />}
            className="add-btn"
            onClick={() => navigate("/process/cirp/" + id + "/add-claim")}
            
          >
            Add Claim
          </Button>
        </Card.Header>
        <Card.Body>
          {AllClaim.isLoading ? (
            <div>Loading claims...</div>
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  {columns.map((col, index) => (
                    <th key={index}>{col.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
    {AllClaim.data?.data && AllClaim.data.data.length > 0 ? (
        AllClaim.data.data.map((claim) => (
            <tr
                key={claim.id}
                onClick={() => 
                    navigate(`/process/cirp/${claim.assignment_id}/case-summary/view-cirp-claim`, { state: { row: claim } })
                }
            >
                {columns.map((col) => (
                    <td key={col.key}>{claim[col.key]}</td>
                ))}
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={columns.length} className="text-center">
                No claims available
            </td>
        </tr>
    )}
</tbody>
            </table>
          )}
        </Card.Body>
      </Card>  
    </Card>
  );
};

export default CirpClaim;
