import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Cookies from "js-cookie";
import routes from "./services/routes";
import LoadingSpinner from "./components/LoadingSpinner"; // Custom spinner
import Other from "./context/Other";
import Mui from "./context/Mui";
import Bootstrap from "./context/Bootstrap";
import Icon from "./context/Icon";
import Leftsidebar from "./components/Leftsidebar";
import LoginPage from "./pages/LoginPage";
import { QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient();


function App() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  React.useEffect(() => {
    if (Cookies.get("role") && Cookies.get("user_id")) {
      setLoggedIn(true);
    }
    setLoading(false);
  }, [loggedIn, loading]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Other>
        <Mui>
          <Bootstrap>
            <Icon>
              <Router>
                <Suspense fallback={<LoadingSpinner />}>
                  <Routes>
                    {!loggedIn ? (
                      <>
                        <Route
                          path="/"
                          element={
                            <LoginPage
                              setLoading={setLoading}
                              setLoggedIn={setLoggedIn}
                            />
                          }
                        />
                        <Route path="*" element={<LoginPage />} />
                      </>
                    ) : (
                      <Route
                        element={
                          <Leftsidebar
                            setLoading={setLoading}
                            setLoggedIn={setLoggedIn}
                          />
                        }
                      >
                        {routes.map((route, index) => (
                          <Route
                            key={index}
                            path={route.path}
                            element={<route.component />}
                          />
                        ))}
                      </Route>
                    )}
                  </Routes>
                </Suspense>
              </Router>
            </Icon>
          </Bootstrap>
        </Mui>
      </Other>
    </QueryClientProvider>
  );
}

export default App;
