import React from "react";
import { Plus } from "lucide-react";
import { useBootstrap, useMui, useOther } from "../../hooks/Hooks";
import CustomDialog from "../../components/CustomDialog";
import FormUtils from "../../utils/FormUtils";
import useUserHooks from "../../hooks/useUserHooks";
import CustomDatatable from "../../components/CustomDatatable"; // Import CustomDatatable

const AllPeople = () => {
  const { Card, Row, Col } = useBootstrap();
  const { Button } = useMui();
  const { Formik, Form } = useOther();
  const { CustomInputField, CustomFormGroup } = FormUtils();

  const [addDialog, setAddDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1); // For pagination
  const limit = 10; // Define items per page

  const {
    useAddUserHook,
    useUpdateUserHook,
    useDeleteUserHook,
    useFetchUserData,
  } = useUserHooks();
  const { mutate: mutateAdd, isLoading: isLoadingAdd } = useAddUserHook();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateUserHook();
  const { mutate: mutateDelete, isLoading: isLoadingDelete } =
    useDeleteUserHook();
  const { data, isLoading, error } = useFetchUserData(currentPage, limit);

  // Define columns for the datatable
  const columns = [
    { label: "Username", key: "username" },
    { label: "Email", key: "email" },
    { label: "Role", key: "role" },
    { label: "Phone Number", key: "phone_number" },
    {
      label: "Actions",
      key: "actions",
      render: (row) => (
        <div>
          <Button onClick={() => handleEditUser(row)}>Edit</Button>
          <Button onClick={() => handleDeleteUser(row.user_id)}>Delete</Button>
        </div>
      ),
    },
  ];

  const handleAddPerson = (values) => {
    mutateAdd(values, {
      onSuccess: () => {
        alert("Person added successfully");
        setAddDialog(false);
        // Optionally refetch user data here
      },
      onError: (error) => {
        console.error("Error submitting form: ", error);
        alert("There was an error submitting the form");
      },
    });
  };

  const handleEditPerson = (values) => {
    mutateUpdate(values, {
      onSuccess: () => {
        alert("User updated successfully");
        setEditDialog(false);
        setSelectedUser(null);
        // Optionally refetch user data here
      },
      onError: (error) => {
        console.error("Error updating user: ", error);
        alert("There was an error updating the user");
      },
    });
  };

  const handleDeleteUser = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      mutateDelete(id, {
        onSuccess: () => {
          alert("User deleted successfully");
          // Optionally refetch user data here
        },
        onError: (error) => {
          console.error("Error deleting user: ", error);
          alert("There was an error deleting the user");
        },
      });
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditDialog(true);
  };

  return (
    <Card className="custom-card">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <input type="text" placeholder="Search" className="custom-dt-input" />
        <div className="d-flex gap-3 align-items-center">
          <div className="custom-select-wrapper">
            <select className="custom-dt-select">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
          </div>
          <Button
            variant="contained"
            startIcon={<Plus size={18} />}
            className="add-btn"
            onClick={() => setAddDialog(true)}
          >
            Add Person
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error fetching user data: {error.message}</p>}
        {!isLoading && data && (
          <CustomDatatable
            columns={columns}
            data={data.results} // Adjust based on your API response structure
            currentPage={currentPage} // Current page for pagination
            onPageChange={setCurrentPage} // Handle page changes
          />
        )}
      </Card.Body>
      <CustomDialog
        width="xs"
        open={addDialog}
        handleDialogClose={() => setAddDialog(false)}
        dialogTitle="Create new Person"
      >
        <Formik
          initialValues={{
            username: "",
            password: "",
            email: "",
            role: "",
            phone_number: "",
          }}
          onSubmit={handleAddPerson}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Row>
                <CustomFormGroup
                  md={12}
                  formlabel="Username"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      error={touched.username && !!errors.username}
                      helperText={touched.username && errors.username}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Password"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Role"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="role"
                      placeholder="Enter Role"
                      error={touched.role && !!errors.role}
                      helperText={touched.role && errors.role}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Email ID"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Phone Number"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="tel"
                      name="phone_number"
                      placeholder="Enter phone number"
                      error={touched.phone_number && !!errors.phone_number}
                      helperText={touched.phone_number && errors.phone_number}
                    />
                  }
                />
                <Col>
                  <Button type="submit" variant="contained" className="add-btn">
                    Add
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="contained"
                    className="cancel-btn"
                    onClick={() => setAddDialog(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomDialog>

      <CustomDialog
        width="xs"
        open={editDialog}
        handleDialogClose={() => setEditDialog(false)}
        dialogTitle="Edit Person"
      >
        <Formik
          initialValues={{
            username: selectedUser?.username || "",
            password: "", // Optionally make this a required field
            email: selectedUser?.email || "",
            role: selectedUser?.role || "",
            phone_number: selectedUser?.phone_number || "",
            user_id: selectedUser?.user_id || "",
          }}
          onSubmit={handleEditPerson}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Row>
                <CustomFormGroup
                  md={12}
                  formlabel="Username"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      error={touched.username && !!errors.username}
                      helperText={touched.username && errors.username}
                      value={values.username}
                      onChange={handleChange}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Email ID"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      value={values.email}
                      onChange={handleChange}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Role"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="text"
                      name="role"
                      placeholder="Enter Role"
                      error={touched.role && !!errors.role}
                      helperText={touched.role && errors.role}
                      value={values.role}
                      onChange={handleChange}
                    />
                  }
                />
                <CustomFormGroup
                  md={12}
                  formlabel="Phone Number"
                  star="*"
                  FormField={
                    <CustomInputField
                      type="tel"
                      name="phone_number"
                      placeholder="Enter phone number"
                      error={touched.phone_number && !!errors.phone_number}
                      helperText={touched.phone_number && errors.phone_number}
                      value={values.phone_number}
                      onChange={handleChange}
                    />
                  }
                />
                <Col>
                  <Button type="submit" variant="contained" className="add-btn">
                    Update
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="contained"
                    className="cancel-btn"
                    onClick={() => setEditDialog(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomDialog>
    </Card>
  );
};

export default AllPeople;
