import React from "react";
import { useOther } from "../hooks/Hooks";

const UseSchema = () => {
  const { Yup } = useOther();

  const LoginSchema = Yup.object({
    username: Yup.string().required("username is required"),
    password: Yup.string().required("Password is required"),
  });

  const AddCirpSchema = Yup.object({
    cd_name: Yup.string().required("CD Name is required"),
    cin_no: Yup.string().required("CIN No. is required"),
    applicant_name: Yup.string().required("Applicant Name is required"),
    applicant_email: Yup.string().required("Applicant Email is required"),
    nclt_order_no: Yup.string().required("NCLT Order No. is required"),
    stage: Yup.string().required("Stage is required"),
    default_amount: Yup.string().required("Default Amount is required"),
    order_date: Yup.string().required("Order Date is required"),
    receipt_date: Yup.string().required("Receipt Date is required"),
    application_filing_date: Yup.string().required("Application Filing Date is required"),
  });

  return { LoginSchema };
};

export default UseSchema;
