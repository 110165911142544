import React, { useState } from "react";
import { useBootstrap, useIcon, useMui, useOther } from "../../hooks/Hooks";
import { Box, TextField } from "@mui/material";
import CustomDialog from "../../components/CustomDialog";
import useCIRPStageHook from "../../hooks/useCIRPStageHook";
import useCIRPMilestoneHook from "../../hooks/useCIRPMilestoneHook";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useCIRPTaskHook from "../../hooks/useCIRPTaskHook";
import SmallDatatable from "../../components/SmallDatatable";

const CIRPTask = () => {
  const { Card } = useBootstrap();
  const { Button } = useMui();
  const { Plus } = useIcon();
  const { useQuery } = useOther();

  const { useAddCIRPTaskHook, setAllCIRPTaskHook, useDeleteCIRPTaskHook } =
    useCIRPTaskHook();
  const { setAllCIRPStageHook } = useCIRPStageHook();
  const { setAllCIRPMilestoneHook } = useCIRPMilestoneHook();

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    stage_id: "",
    milestone_id: "",
    task_label: "",
    task_desc: "",
    day_of_complete: "",
  });

  const AllCIRPTask = useQuery("AllCIRPTask", () => {
    return setAllCIRPTaskHook();
  });

  const AllCIRPStage = useQuery("AllCIRPStage", () => {
    return setAllCIRPStageHook();
  });

  const AllCIRPMilestone = useQuery("AllCIRPMilestone", () => {
    return setAllCIRPMilestoneHook();
  });

  const { mutate, isLoading } = useAddCIRPTaskHook();
  const { mutate: deleteMutate, isLoading: deleteIsLoading } =
    useDeleteCIRPTaskHook();

  // Update state as form inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    mutate(formData, {
      onSuccess: (data) => {
        AllCIRPTask.refetch();
        setOpen(false);
        // You can trigger a reload of the datatable here or show a success message
      },
      onError: (error) => {
        console.error("Error occurred while submitting the form:", error);
      },
    });
  };

  const handleDelete = (data) => {
    deleteMutate(data, {
      onSuccess: () => {
        AllCIRPTask.refetch();
        // You can trigger a reload of the datatable here or show a success message
      },
      onError: (error) => {
        console.error("Error occurred while deleting:", error);
      },
    });
  };

  if (
    AllCIRPStage.isLoading ||
    AllCIRPMilestone.isLoading ||
    AllCIRPTask.isLoading
  ) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="custom-card">
      <Card.Body className="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <Card.Title>CIRP Task</Card.Title>
          <Button
            variant="contained"
            startIcon={<Plus size={18} />}
            className="add-btn"
            onClick={() => setOpen(true)}
          >
            Add Task
          </Button>
        </div>
        <SmallDatatable
          columns={[
            "Task ID",
            "Created Date",
            "Stage Name",
            "Milestone",
            "Task Label",
          ]}
          rows={AllCIRPTask.data}
          onDelete={handleDelete}
        />
        <CustomDialog
          open={open}
          handleDialogClose={() => setOpen(false)}
          dialogTitle="Add CIRP Stage"
        >
          <Box component="form" onSubmit={handleFormSubmit}>
            <FormControl variant="standard" className="w-100">
              <InputLabel id="demo-simple-select-standard-label">
                Select Stage
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                name="stage_id"
                value={formData.stage}
                onChange={handleInputChange}
                label="Select Stage"
              >
                {AllCIRPStage.data.map((row) => (
                  <MenuItem value={row.stage_id}>{row.stage_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" className="w-100 mt-3">
              <InputLabel id="demo-simple-select-standard-label">
                Select Milestone
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                name="milestone_id"
                value={formData.milestone_id}
                onChange={handleInputChange}
                label="Select Stage"
              >
                {AllCIRPMilestone.data
                  .filter(
                    (milestone) =>
                      milestone.milestone_stage === formData.stage_id
                  )
                  .map((row) => (
                    <MenuItem value={row.milestone_id}>
                      {row.milestone}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              required
              className="mt-3"
              name="task_label"
              label="Task Label"
              type="text"
              fullWidth
              variant="standard"
              value={formData.task_label}
              onChange={handleInputChange}
            />
            <TextField
              rows={5}
              className="mt-3"
              name="task_desc"
              label="Task Description"
              type="text"
              fullWidth
              variant="standard"
              value={formData.task_desc}
              onChange={handleInputChange}
            />
            <TextField
              className="mt-3"
              required
              name="day_of_complete"
              label="Completed after creating case"
              type="number"
              fullWidth
              variant="standard"
              value={formData.day_of_complete}
              onChange={handleInputChange}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button type="submit" color="primary" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </Box>
          </Box>
        </CustomDialog>
      </Card.Body>
    </Card>
  );
};

export default CIRPTask;
