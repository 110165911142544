import axios from "../../services/axios";
import { useOther } from "../Hooks";

const useCaseSummaryHook = () => {
  const { useMutation, systemDT } = useOther();

  // Function that makes the POST request
  const setAddCaseSummaryHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/add-creditor-information`,
        {
          ...data,
          systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const setEditCaseSummaryHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/edit-creditor-information`,
        {
          ...data,
          systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const setEditPromoterInformationHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/edit-promoter-information`,
        {
          ...data,
          systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };
  // Function that makes the POST request
  const setDeleteCaseSummaryHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/delete-creditor-information`,
        {
          ...data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const getCreditorInformationDataHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/get-creditor-information`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const setAddPromoterInformationHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/add-promoter-information`,
        {
          ...data,
          systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const getPromoterInformationDataHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/get-promoter-information`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const getCorporateDebtorInfoDataHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/get-corporate-debtor-information`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const setCorporateDebtorInformationEditHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-summary/edit-corporate-debtor-information`,
        {
          ...data,
          systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useAddPromoterInformationHook = () => {
    return useMutation(setAddPromoterInformationHook); // return the mutation hook
  };
  // Hook that uses mutation to call the POST request function
  const useCorporateDebtorInformationEditHook = () => {
    return useMutation(setCorporateDebtorInformationEditHook); // return the mutation hook
  };

  // Hook that uses mutation to call the POST request function
  const useAddCaseSummaryHook = () => {
    return useMutation(setAddCaseSummaryHook); // return the mutation hook
  };

  // Hook that uses mutation to call the POST request function
  const useEditCaseSummaryHook = () => {
    return useMutation(setEditCaseSummaryHook); // return the mutation hook
  };
  // Hook that uses mutation to call the POST request function
  const useEditPromoterInformationHook = () => {
    return useMutation(setEditPromoterInformationHook); // return the mutation hook
  };

  // Hook that uses mutation to call the POST request function
  const useDeleteCaseSummaryHook = () => {
    return useMutation(setDeleteCaseSummaryHook); // return the mutation hook
  };

  return {
    useAddPromoterInformationHook,
    getPromoterInformationDataHook,
    useEditPromoterInformationHook,
    useAddCaseSummaryHook,
    useEditCaseSummaryHook,
    useDeleteCaseSummaryHook,
    getCreditorInformationDataHook,
    getCorporateDebtorInfoDataHook,
    useCorporateDebtorInformationEditHook,
  };
};

export default useCaseSummaryHook;
