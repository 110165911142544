import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogContentText, DialogTitle } from "@mui/material";

const CustomDialog = ({
  open,
  handleDialogClose,
  children,
  dialogTitle,
  dialogContentText,
  dialogActionBtn,
  width,
}) => {
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleDialogClose} maxWidth={ width || "sm"}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          {children}
        </DialogContent>
        {dialogActionBtn && <DialogActions>{dialogActionBtn}</DialogActions>}
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
