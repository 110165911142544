import axios from "../../services/axios";
import { useOther } from "../Hooks";

const useCaseMeetingHook = () => {
  const { useMutation, systemDT } = useOther();

  const getCirpCaseMeetingHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-meeting/get-cirp-case-meeting`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  const setCirpCasePostMeetingHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp/case-meeting/set-cirp-case-post-meeting`,
        {
          data,
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  const useCirpCasePostMeetingHook = () => {
    return useMutation(setCirpCasePostMeetingHook); // return the mutation hook
  };

  return { getCirpCaseMeetingHook, useCirpCasePostMeetingHook };
};

export default useCaseMeetingHook;
