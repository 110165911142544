// src/routes.js

import { lazy } from "react";
import CirpSummaryPage from "../pages/CirpSummaryPage";
import AddCIRPPage from "../pages/AddCRIP";
import CirpCaseRun from "../pages/CIRP/CirpCaseRun";
import CIRPStage from "../pages/CIRPStage";
import CIRPMilestone from "../pages/CIRPMilestone";
import CIRPTask from "../pages/Dyanamics/CIRPTask";
import AllPeople from "../pages/People/AllPeople";
import CirpMeeting from "../pages/CIRP/CirpMeeting";
import CaseRunTaskDetails from "../pages/CIRP/CaseRunTaskDetails";
import CirpParticipants from "../pages/CIRP/CirpParticipants";
import CirpDocumentPage from "../pages/CirpDocumentPage";
import CirpClaim from "../pages/CirpClaim";
import CirpViewClaim from "../pages/CirpViewClaim.js";
import AddClaim from "../pages/AddClaim";
import CaseExtendTaskDetails from "../pages/CIRP/CaseExtendTaskDetails.js";

// Lazy load the components
const CirpPage = lazy(() => import("./../pages/CirpPage"));
const LoginPage = lazy(() => import("./../pages/LoginPage"));
const DashboardPage = lazy(() => import("./../pages/DashboardPage"));
const NotFoundPage = lazy(() => import("./../pages/NotFoundPage"));

// Define routes as an array of objects
const routes = [
  // =================== People ====================
  {
    path: "/all-people",
    component: AllPeople,
  },
  {
    path: "/process/cirp",
    component: CirpPage,
  },
  {
    path: "/process/cirp/add",
    component: AddCIRPPage,
  },
  {
    path: "/process/cirp/:id/case-summary",
    component: CirpSummaryPage,
  },
  {
    path: "/process/cirp/:id/case-runs",
    component: CirpCaseRun,
  },
  {
    path: "/process/cirp/:id/case-runs/:stage_id/:milestone_id/:task_id",
    component: CaseRunTaskDetails,
  },
  {
    path: "/process/cirp/:id/case-runs/extend/:ect_id",
    component: CaseExtendTaskDetails,
  },
  {
    path: "/process/cirp/:id/case-meeting",
    component: CirpMeeting,
  },
  {
    path: "/process/cirp/:id/case-participants",
    component: CirpParticipants,
  },
  {
    path: "/process/cirp/:id/case-documents",
    component: CirpDocumentPage,
  },

  {
    path: "/process/cirp/:id/claims",
    component: CirpClaim,
  },

  {
    path: "/process/cirp/:id/case-summary/view-cirp-claim",
    component: CirpViewClaim,
  },
  {
    path: "/process/cirp/:id/add-claim",
    component: AddClaim,
  },
  // =================== Dyanamics ===================
  {
    path: "/dyanamics/cirp-stage",
    component: CIRPStage,
  },
  {
    path: "/dyanamics/cirp-milestone",
    component: CIRPMilestone,
  },
  {
    path: "/dyanamics/cirp-task",
    component: CIRPTask,
  },
  {
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    path: "*", // This will handle 404
    component: NotFoundPage,
  },
];

export default routes;
