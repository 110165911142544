import axios from "../services/axios";
import { useOther } from "./Hooks";

const useUserHooks = () => {
  const { systemDT, useQuery, useMutation } = useOther();



  // Function to fetch paginated user data
  const FetchUserData = async ({ queryKey }) => {
    const [_, currentPage, pageSize] = queryKey;
    const { data } = await axios.get(`/user/all-user-data-with-pagination`, {
      params: {
        page: currentPage,
        limit: pageSize,
      },
    });
    return data; // Return the paginated data
  };

  // Hook to fetch user data with pagination
  const useFetchUserData = (currentPage, pageSize) => {
    return useQuery(["allUserData", currentPage, pageSize], FetchUserData, {
      keepPreviousData: true, // Keeps old data while fetching new data
    });
  };

  // Function that makes the POST request for adding a user
  const setAddUserHook = async (data) => {
    try {
      const response = await axios.post(`/user/create-new-user`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the PUT request for updating a user
  const setUpdateUserHook = async (data) => {
    try {
      const response = await axios.put(`/user/edit-user/${data.user_id}`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the DELETE request for deleting a user
  const setDeleteUserHook = async (id) => {
    try {
      const response = await axios.delete(`/user/delete-user/${id}`);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

    // Function that makes the DELETE request for deleting a user
    const getDropdownUser = async (id) => {
      try {
        const response = await axios.get(`/user/get-dropdown-user`);
        return response.data; // returning the response data
      } catch (err) {
        throw new Error(err); // rethrowing the error to be handled in the component
      }
    };
  

  // Hook that uses mutation to call the POST request function for adding a user
  const useAddUserHook = () => {
    return useMutation(setAddUserHook); // return the mutation hook
  };

  // Hook that uses mutation to call the PUT request function for updating a user
  const useUpdateUserHook = () => {
    return useMutation((data) => setUpdateUserHook(data)); // return the mutation hook
  };

  // Hook that uses mutation to call the DELETE request function for deleting a user
  const useDeleteUserHook = () => {
    return useMutation(setDeleteUserHook); // return the mutation hook
  };

  return {
    useAddUserHook,
    useUpdateUserHook,
    useDeleteUserHook,
    getDropdownUser,
    useFetchUserData,
  };
};

export default useUserHooks;
