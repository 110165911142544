import axios from "../services/axios";
import { useOther } from "./Hooks";

const useCIRPHook = () => {
  const { systemDT, useQuery, useMutation } = useOther();

  // Function to fetch paginated CIRP data
  const fetchCIRPData = async ({ queryKey }) => {
    const [_, currentPage, pageSize] = queryKey;
    const { data } = await axios.get(
      `/cirp-process/all-cirp-data-with-pagination`,
      {
        params: {
          page: currentPage,
          limit: pageSize,
        },
      }
    );
    return data; // Return the paginated data (assuming { results: [], total: 100 })
  };

  // Mutation for adding CIRP process
  const setAddCIRPProcessHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-process/add-cirp-process`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Mutation for adding CIRP process
  const setUpdateCIRPProcessHook = async (data) => {
    try {
      const response = await axios.post(
        `/cirp-process/update-cirp-process-details`,
        {
          ...data,
          systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
        }
      );
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  const getCIRPDetails = async (data) => {
    try {
      const response = await axios.post(`/cirp-process/cirp-process-details`, {
        data,
      });
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  };

  // Hook to add CIRP process using mutation
  const useAddCIRPProcessHook = () => {
    return useMutation(setAddCIRPProcessHook);
  };
  const useUpdateCIRPProcessHook = () => {
    return useMutation(setUpdateCIRPProcessHook);
  };

  // Hook to fetch CIRP data with pagination using query
  const useFetchCIRPData = (currentPage, pageSize) => {
    return useQuery(["cirpData", currentPage, pageSize], fetchCIRPData, {
      keepPreviousData: true, // Keeps old data while fetching new data
    });
  };

  return {
    useAddCIRPProcessHook,
    useFetchCIRPData,
    getCIRPDetails,
    useUpdateCIRPProcessHook,
  };
};

export default useCIRPHook;
