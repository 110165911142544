import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Skeleton, // Import Skeleton for loading state
  Tooltip, // Import Tooltip for action buttons
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

const TableCurdHandler = ({
  columns,
  fields,
  initialData,
  defaultAction = true,
  handleAdd,
  handleEdit,
  handleDelete,
  handleView,
  loading = false,
  showSubmitButton = false, // New prop to control submit button visibility
}) => {
  console.log(initialData, "initialData");

  const [entries, setEntries] = useState(initialData);
  const [newData, setNewData] = useState({});
  const [editingIndex, setEditingIndex] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);

  const handleCloseEdit = () => setOpenEdit(false);
  const handleShowEdit = () => {
    setNewData({});
    setEditingIndex(null);
    setOpenEdit(true);
  };

  const handleSave = () => {
    if (editingIndex !== null) {
      if (handleEdit) handleEdit(newData, editingIndex);
      const updatedEntries = [...entries];
      updatedEntries[editingIndex] = newData;
      setEntries(updatedEntries);
    } else {
      if (handleAdd) handleAdd(newData);
      setEntries([...entries, newData]);
    }
    handleCloseEdit();
  };

  const handleRowEdit = (index) => {
    setEditingIndex(index);
    setNewData(entries[index]);
    setOpenEdit(true);
  };

  const handleRowDelete = (index) => {
    if (handleDelete) handleDelete(index);
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
  };

  const handleRowView = (entry) => {
    setNewData(entry);
    setOpenView(true);
    if (handleView) handleView(entry);
  };

  const handleSubmit = () => {
    console.log("Submit data:", entries);
    // Add your form submission logic here.
  };

  return (
    <div>
      {!defaultAction && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowEdit}
          style={{ marginBottom: "20px" }}
        >
          Add Entry
        </Button>
      )}
      <TableContainer component={Paper}>
        {loading ? (
          // Show Skeleton when loading
          <Table>
            <TableBody>
              {[...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  {columns.map((_, columnIndex) => (
                    <TableCell key={columnIndex} align="left">
                      <Skeleton variant="text" width="100%" height={40} />
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    <Skeleton variant="rectangular" width={50} height={50} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, idx) => (
                  <TableCell
                    key={idx}
                    align="left"
                    style={{ fontWeight: "bold" }}
                  >
                    {col.label}
                  </TableCell>
                ))}
                <TableCell align="center" style={{ fontWeight: "bold" }}>
                  ACTIONS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.length > 0 ? (
                entries.map((entry, idx) => (
                  <TableRow key={idx}>
                    {columns.map((col, columnIndex) => (
                      <TableCell key={columnIndex} align="left">
                        {entry[col.field]}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      {!defaultAction && (
                        <>
                          <Tooltip title="Edit" arrow>
                            <Button
                              variant="text"
                              onClick={() => handleRowEdit(idx)}
                            >
                              <EditIcon color="primary" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="View" arrow>
                            <Button
                              variant="text"
                              onClick={() => handleRowView(entry)}
                            >
                              <VisibilityIcon color="action" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>
                            <Button
                              variant="text"
                              onClick={() => handleRowDelete(idx)}
                            >
                              <DeleteIcon color="error" />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                    <Typography variant="body1" color="textSecondary">
                      No records found. Click here to Add New Entries.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {/* Add/Edit Dialog */}
      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>
          {editingIndex !== null ? "Edit Entry" : "Add New Entry"}
        </DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <TextField
              key={index}
              label={field.label}
              type={field.type || "text"}
              value={newData[field.field] || ""}
              onChange={(e) =>
                setNewData({ ...newData, [field.field]: e.target.value })
              }
              placeholder={`Enter ${field.label}`}
              fullWidth
              margin="normal"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="primary">
            Close
          </Button>
          <Button onClick={handleSave} color="primary">
            {editingIndex !== null ? "Update Entry" : "Add Entry"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Dialog */}
      <Dialog open={openView} onClose={() => setOpenView(false)}>
        <DialogTitle>View Entry</DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <TextField
              key={index}
              label={field.label}
              type={field.type || "text"}
              value={newData[field.field] || ""}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              margin="normal"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenView(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Submit Button */}
      {showSubmitButton && !defaultAction && (
        <Button
          variant="contained"
          color="success"
          onClick={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          Submit All Entries
        </Button>
      )}
    </div>
  );
};

export default TableCurdHandler;
