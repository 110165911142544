import axios from "../services/axios";
import { useOther } from "./Hooks";

const useCIRPTaskHook = () => {
  const { useMutation, systemDT } = useOther();

  // Function that makes the POST request
  const setAddCIRPTaskHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-task/add`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const ExtendCIRPTaskHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-task/create-extend-task`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const setDeleteCIRPTaskHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-task/delete`, data);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useDeleteCIRPTaskHook = () => {
    return useMutation(setDeleteCIRPTaskHook); // return the mutation hook
  };

  // Function that makes the POST request
  const setAllCIRPTaskHook = async () => {
    try {
      const response = await axios.post(`/cirp-task/all-task-data-in-details`);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const getCIRPExtendTaskHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-task/get-all-extend-task`, {
        data,
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const getCIRPTaskDetailsHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-task/get-task-details`, {
        data,
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Function that makes the POST request
  const getCIRPExtendTaskDetailsHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-task/get-extend-task-details`, {
        data,
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useAddCIRPTaskHook = () => {
    return useMutation(setAddCIRPTaskHook); // return the mutation hook
  };
  // Hook that uses mutation to call the POST request function
  const useExtendCIRPTaskHook = () => {
    return useMutation(ExtendCIRPTaskHook); // return the mutation hook
  };

  return {
    setAllCIRPTaskHook,
    useAddCIRPTaskHook,
    useDeleteCIRPTaskHook,
    getCIRPTaskDetailsHook,
    getCIRPExtendTaskDetailsHook,
    useExtendCIRPTaskHook,
    getCIRPExtendTaskHook,
  };
};

export default useCIRPTaskHook;
