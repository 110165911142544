import React from "react";
import { useBootstrap, useIcon, useMui, useOther } from "../../hooks/Hooks";
import CIRPHeader from "../../components/CIRPHeader";
import useCaseSummaryHook from "../../hooks/CIRP/useCaseParticipantsHook";
import Table from "react-bootstrap/Table";
import useUserHooks from "../../hooks/useUserHooks";
import { IconButton } from "@mui/material";
import { FilePenLine, Trash2 } from "lucide-react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const CirpParticipants = () => {
  const { Card } = useBootstrap();
  const { Divider, Button } = useMui();
  const { Plus } = useIcon();
  const { useParams, useQuery } = useOther();

  const { id } = useParams();
  const { getDropdownUser } = useUserHooks();

  const {
    getCirpCaseParticipantsHook,
    useEditCaseParticipantsHook,
    useAddCaseParticipantsHook,
    useDeleteCaseParticipantsHook,
  } = useCaseSummaryHook();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false); // Tracks whether we are in edit mode
  const [memberData, setMemberData] = React.useState({
    user_id: "",
    role: "",
  });

  const { mutate: AddMutate, isLoading: AddIsLoading } =
    useAddCaseParticipantsHook();
  const { mutate: EditMutate, isLoading: EditIsLoading } =
    useEditCaseParticipantsHook();
  const { mutate: DeleteMutate, isLoading: DeleteIsLoading } =
    useDeleteCaseParticipantsHook();

  const allCIRPCaseParticipants = useQuery(
    "all-cirp-case-participants" + id,
    () => {
      return getCirpCaseParticipantsHook(id);
    }
  );

  const AllUser = useQuery("AllUser", () => {
    return getDropdownUser();
  });

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setIsEditing(false); // Reset editing mode when dialog is closed
    setMemberData({
      user_id: "",
      role: "",
    });
  };

  const handleMemberChange = (e) => {
    setMemberData({ ...memberData, [e.target.name]: e.target.value });
  };

  const handleAddMember = () => {
    if (memberData.user_id && memberData.role) {
      if (memberData.ctm_id) {
        // Handle update of existing member
        EditMutate(
          { ...memberData, cirp_id: id },
          {
            onSuccess: (response) => {
              if (response === "Participant Updated") {
                allCIRPCaseParticipants.refetch();
                alert("Updated Successfully");
              } else {
                alert("Something went wrong");
              }
              setMemberData({
                user_id: "",
                role: "",
              })
              handleDialogClose();
            },
            onError: () => {
              alert("Failed to update participant.");
            },
          }
        );
      } else {
        // Handle adding a new member
        AddMutate(
          { ...memberData, cirp_id: id },
          {
            onSuccess: (response) => {
              if (response === "Participant Added to Case") {
                allCIRPCaseParticipants.refetch();
                alert("Added Successfully");
              } else {
                alert("Something went wrong");
              }
            },
          }
        );
      }
      handleDialogClose();
    } else {
      alert("Please select both a user and a role.");
    }
  };

  const getUsernameById = (user_id) => {
    const user = AllUser?.data?.results.find(
      (user) => user.user_id === user_id
    );
    return user ? user.username : "Unknown User";
  };

  const handleEdit = (row) => {
    // Populate the dialog form with the selected row data for editing
    setMemberData({
      user_id: row.member_id,
      role: row.role,
      ctm_id: row.ctm_id,
    });
    setIsEditing(true); // Set to edit mode
    setOpenDialog(true);
  };

  const handleDelete = (row) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this participant?"
    );
    if (confirmDelete) {
      // Trigger the mutation to delete the participant
      DeleteMutate(row.ctm_id, {
        onSuccess: (response) => {
          if (response === "Participant Deleted from Case") {
            allCIRPCaseParticipants.refetch();
            alert("Participant deleted successfully.");
          } else {
            alert("Failed to delete participant.");
          }
        },
        onError: () => {
          alert("An error occurred while deleting the participant.");
        },
      });
    }
  };

  if (allCIRPCaseParticipants.isLoading || AllUser.isLoading) {
    return <p>Loading....</p>;
  }

  return (
    <>
      <Card className="custom-card">
        <Card.Body className="p-3">
          <CIRPHeader />
          <Divider />
        </Card.Body>
      </Card>
      <Card className="custom-card mt-3">
        <Card.Body className="p-3">
          <div className="d-flex align-center justify-content-between">
            <p>All Participants</p>
            <Button
              variant="contained"
              startIcon={<Plus size={18} />}
              className="add-btn"
              onClick={handleDialogOpen}
            >
              Add Participants
            </Button>
          </div>

          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Sr no</th>
                <th>User ID</th>
                <th>Username</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allCIRPCaseParticipants.data.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.member_id}</td>
                    <td>{getUsernameById(row.member_id)}</td>
                    <td>{row.role}</td>
                    <td>
                      {/* <IconButton onClick={() => handleEdit(row)}>
                        <FilePenLine size={20} color="#333" />
                      </IconButton> */}
                      <IconButton onClick={() => handleDelete(row)}>
                        <Trash2 size={20} color="#333" />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Dialog open={openDialog} onClose={handleDialogClose}>
            <DialogTitle>
              {isEditing ? "Edit Member" : "Add New Member"}
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Select User
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={memberData.user_id}
                  label="Select User"
                  name="user_id"
                  onChange={handleMemberChange}
                  disabled={isEditing} // Disable user selection in edit mode
                >
                  {AllUser?.data?.results
                    .filter(
                      (user) =>
                        !allCIRPCaseParticipants.data.some(
                          (member) => member.member_id === user.user_id
                        )
                    )
                    .map((user) => (
                      <MenuItem key={user.user_id} value={user.user_id}>
                        {user.username}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={memberData.role}
                  label="Select Role"
                  name="role"
                  onChange={handleMemberChange}
                >
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  <MenuItem value={"Team Leader"}>Team Leader</MenuItem>
                  <MenuItem value={"Manager"}>Manager</MenuItem>
                  <MenuItem value={"Member"}>Member</MenuItem>
                  <MenuItem value={"Guest"}>Guest</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={AddIsLoading || EditIsLoading ? null : handleAddMember}
                color="primary"
              >
                {isEditing ? "Update Member" : "Add Member"}
              </Button>
            </DialogActions>
          </Dialog>
        </Card.Body>
      </Card>
    </>
  );
};

export default CirpParticipants;
