import { useContext } from "react";
import { MuiContext, IconContext, BootstrapContext, OtherContext } from "../context/Context";

function useMui() {
  return useContext(MuiContext);
}

function useIcon() {
  return useContext(IconContext);
}

function useBootstrap() {
  return useContext(BootstrapContext);
}

function useOther() {
  return useContext(OtherContext);
}

// function useComponent() {
//   return useContext(ComponentContext);
// }

export { useMui, useIcon, useBootstrap, useOther };
