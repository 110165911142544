import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="spinner">
      <p>Loading...</p> {/* Replace with actual spinner component if needed */}
    </div>
  );
};

export default LoadingSpinner;
