import { Box, Button, Typography, Tabs, Tab } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { ChevronLeft } from "lucide-react";
import useCIRPHook from "../hooks/useCIRPHook";
import "./../styles/CIRPHeader.css";
import { useOther } from "../hooks/Hooks";

const CIRPHeader = () => {
  const { useQuery, useParams } = useOther();
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();

  const { getCIRPDetails } = useCIRPHook();

  const CirpProcessDetails = useQuery("CIRPProcessDetails-" + id, () => {
    return getCIRPDetails(id);
  });

  // List of paths for the tabs
  const tabPaths = [
    { path: `/process/cirp/${id}/case-summary`, label: "Case Summary" },
    { path: `/process/cirp/${id}/case-runs`, label: "Case Runs" },
    { path: `/process/cirp/${id}/claims`, label: "Claims" },
    { path: `/process/cirp/${id}/case-meeting`, label: "Meetings" },
    { path: `/process/cirp/${id}/case-participants`, label: "Participants" },
    { path: `/process/cirp/${id}/case-documents`, label: "Documents" },
  ];

  // Find the active tab based on the current URL
  const findActiveTab = () => {
    const currentPath = location.pathname;
    const activeTab = tabPaths.findIndex((tab) =>
      currentPath.includes(tab.path)
    );
    return activeTab !== -1 ? activeTab : 0; // Default to first tab if no match
  };

  const [activeTab, setActiveTab] = useState(findActiveTab());

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    navigate(tabPaths[newValue].path); // Navigate to the selected tab's path
  };

  if (CirpProcessDetails.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Box
        flexDirection={"row"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="mb-3"
      >
        <div>
          <Typography variant="h5" sx={{ fontWeight: "bold", marginRight: 1 }}>
            {CirpProcessDetails.data[0].cd_name}
          </Typography>
          <Typography
            variant="span"
            sx={{ fontWeight: "bold", color: "#6b7280" }}
          >
            {"Case ID :- " + CirpProcessDetails.data[0].cirp_id}
          </Typography>
        </div>
        <Button
          variant="contained"
          startIcon={<ChevronLeft />}
          onClick={() => navigate(-1)} // Corrected `onClick`
        >
          Back
        </Button>
      </Box>

      {/* Tabs Section */}
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="CIRP process tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabPaths.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              component={Link}
              className="custom-tab"
              to={tab.path}
              sx={{
                fontWeight: activeTab === index ? "bold" : "normal",
              }}
            />
          ))}
        </Tabs>
      </Box>
    </>
  );
};

export default CIRPHeader;
