import React from "react";
import { useBootstrap, useIcon, useMui, useOther } from "../hooks/Hooks";
import TableCurdHandler from "../services/TableCurdHandler";
import Cirputils from "../utils/Cirputils";
import useCaseSummaryHook from "../hooks/CIRP/useCaseSummaryHook";
import FormUtils from "../utils/FormUtils";

const DebtorsProfile = () => {
  const { Accordion, AccordionSummary, AccordionDetails, ExpandMoreIcon } =
    useMui();
  const { Row, Col } = useBootstrap();
  const { Button } = useMui();
  const { FilePenLine, ChevronLeft } = useIcon();
  const { useParams, useQuery, Formik, Form } = useOther();

  const { CustomInputField, CustomFormGroup } = FormUtils();
  const {
    useAddPromoterInformationHook,
    getPromoterInformationDataHook,
    useEditPromoterInformationHook,
    getCreditorInformationDataHook,
    getCorporateDebtorInfoDataHook,
    useEditCaseSummaryHook,
    useAddCaseSummaryHook,
    useDeleteCaseSummaryHook,
    useCorporateDebtorInformationEditHook,
  } = useCaseSummaryHook();
  const { id } = useParams();

  // Define your creditor columns and fields
  const {
    CreditorInformationColumns,
    CreditorInformationFields,
    PromoterInformationColumns,
    PromoterInformationFields,
  } = Cirputils();

  const [editDetails, setEditDetails] = React.useState(false);

  const {
    mutate: PromoterInformatioAddMutate,
    isLoading: PromoterInformatioAddIsLoading,
  } = useAddPromoterInformationHook();
  const {
    mutate: CreditorInformationAddMutate,
    isLoading: CreditorInformationAddIsLoading,
  } = useAddCaseSummaryHook();

  const {
    mutate: CreditorInformationEditMutate,
    isLoading: CreditorInformationEditIsLoading,
  } = useEditCaseSummaryHook();
  const {
    mutate: PromoterInformationEditMutate,
    isLoading: PromoterInformationEditIsLoading,
  } = useEditPromoterInformationHook();
  const {
    mutate: CreditorInformationDeleteMutate,
    isLoading: CreditorInformationDeletesLoading,
  } = useDeleteCaseSummaryHook();
  const {
    mutate: CorporateDebtorInfoEditMutate,
    isLoading: CorporateDebtorInfoEditLoading,
  } = useCorporateDebtorInformationEditHook();

  const CorporateDebtorInfoData = useQuery(
    "Promoter-Information-Data" + id,
    () => {
      return getCorporateDebtorInfoDataHook(id);
    }
  );

  const PromoterInformationData = useQuery(
    "Promoter-Information-Data" + id,
    () => {
      return getPromoterInformationDataHook(id);
    }
  );

  const CreditorInformationData = useQuery(
    "Creditor-Information-Data" + id,
    () => {
      return getCreditorInformationDataHook(id);
    }
  );

  // Handle adding a new creditor
  const handleCreditorInformationAdd = (newEntry) => {
    CreditorInformationAddMutate(
      { ...newEntry, cirp_id: id },
      {
        onSuccess: (data) => {
          alert("Creditor added successfully");
        },
        onError: (error) => {
          console.error("Error occurred while adding the creditor:", error);
          alert("There was an error adding the creditor");
        },
      }
    );
  };

  const handlePromoterInformationAdd = (newEntry) => {
    PromoterInformatioAddMutate(
      { ...newEntry, cirp_id: id },
      {
        onSuccess: (data) => {
          alert("Creditor added successfully");
        },
        onError: (error) => {
          console.error("Error occurred while adding the creditor:", error);
          alert("There was an error adding the creditor");
        },
      }
    );
  };

  // Handle editing a creditor
  const handleCreditorInformationEdit = (updatedEntry, index) => {
    const creditorId = CreditorInformationData.data?.[index]?.cci_id;
    if (!creditorId) return;
    CreditorInformationEditMutate(
      { ...updatedEntry, id: creditorId },
      {
        onSuccess: (data) => {
          alert("Creditor updated successfully");
        },
        onError: (error) => {
          console.error("Error occurred while updating the creditor:", error);
          alert("There was an error updating the creditor");
        },
      }
    );
  };

  // Handle editing a creditor
  const handlePromoterInformationEdit = (updatedEntry, index) => {
    const promoterId = PromoterInformationData.data?.[index]?.cpi_id;

    if (!promoterId) return;
    PromoterInformationEditMutate(
      { ...updatedEntry, id: promoterId },
      {
        onSuccess: (data) => {
          alert("Promoter updated successfully");
        },
        onError: (error) => {
          console.error("Error occurred while updating the Promoter:", error);
          alert("There was an error updating the Promoter");
        },
      }
    );
  };

  const handleCorporateDebtorInformationEdit = (values) => {
    CorporateDebtorInfoEditMutate(
      { ...values, cirp_id: id },
      {
        onSuccess: (data) => {
          if (data === "success") {
            setEditDetails(false);
            alert("Creditor updated successfully");
          }
        },
        onError: (error) => {
          console.error("Error occurred while updating the creditor:", error);
          alert("There was an error updating the creditor");
        },
      }
    );
  };

  // Handle deleting a creditor
  const handleDelete = (index) => {
    const creditorId = CreditorInformationData.data?.[index]?.cci_id;
    if (!creditorId) return;
    if (window.confirm("Are you sure you want to delete this creditor?")) {
      CreditorInformationDeleteMutate(
        { id: creditorId },
        {
          onSuccess: (data) => {
            alert("Creditor deleted successfully");
          },
          onError: (error) => {
            console.error("Error occurred while deleting the creditor:", error);
            alert("There was an error deleting the creditor");
          },
        }
      );
    }
  };

  const handleView = (entry) => {
    console.log("Viewing entry", entry);
  };

  if (
    CreditorInformationData.isLoading ||
    PromoterInformationData.isLoading ||
    CorporateDebtorInfoData.isLoading
  ) {
    return <div>Loading...</div>;
  }

  const CorporateDebtorInformationArr = [
    {
      title: "Name of Corporate Debtor",
      details: CorporateDebtorInfoData.data[0]?.applicant_name,
    },
    {
      title: "Is CD a listed company",
      details: CorporateDebtorInfoData.data[0]?.listed_company,
    },
    {
      title: "Corporate Identification Number (CIN)",
      details: CorporateDebtorInfoData.data[0]?.cin_no,
    },
    {
      title: "Registered  Email ID of Company",
      details: CorporateDebtorInfoData.data[0]?.company_email,
    },
    {
      title: "Industry",
      details: CorporateDebtorInfoData.data[0]?.industry,
    },
    {
      title: "Company website",
      details: CorporateDebtorInfoData.data[0]?.company_website,
    },
    {
      title: "Contact Person for Corporate Debtor",
      details: CorporateDebtorInfoData.data[0]?.contact_person,
    },
    {
      title: "Contact Person Designation",
      details: CorporateDebtorInfoData.data[0]?.contact_designation,
    },
    {
      title: "Contact Person Phone Number",
      details: CorporateDebtorInfoData.data[0]?.contact_number,
    },
    {
      title: "Contact Person Email ID",
      details: CorporateDebtorInfoData.data[0]?.contact_email,
    },
  ];

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Corporate Debtor Information
        </AccordionSummary>
        <AccordionDetails>
          <Button
            className="m-2"
            style={{ float: "right" }}
            startIcon={
              editDetails ? (
                <ChevronLeft size={20} />
              ) : (
                <FilePenLine size={20} />
              )
            }
            onClick={() => setEditDetails(!editDetails)}
          >
            {editDetails ? "Cancel" : "Edit"}
          </Button>
          {editDetails ? (
            <Formik
              initialValues={{
                applicant_name: CorporateDebtorInfoData.data[0]?.applicant_name,
                listed_company: CorporateDebtorInfoData.data[0]?.listed_company,
                cin_no: CorporateDebtorInfoData.data[0]?.cin_no,
                company_email: CorporateDebtorInfoData.data[0]?.company_email,
                industry: CorporateDebtorInfoData.data[0]?.industry,
                company_website:
                  CorporateDebtorInfoData.data[0]?.company_website,
                contact_person: CorporateDebtorInfoData.data[0]?.contact_person,
                contact_designation:
                  CorporateDebtorInfoData.data[0]?.contact_designation,
                contact_number: CorporateDebtorInfoData.data[0]?.contact_number,
                contact_email: CorporateDebtorInfoData.data[0]?.contact_email,
              }}
              onSubmit={(values) => {
                handleCorporateDebtorInformationEdit(values);
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <CustomFormGroup
                      formlabel="Is CD a listed company"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="listed_company"
                          placeholder="Is CD a listed company"
                          error={
                            touched.listed_company && !!errors.listed_company
                          }
                          helperText={
                            touched.listed_company && errors.listed_company
                          }
                        />
                      }
                    />

                    <CustomFormGroup
                      formlabel="Corporate Identification Number (CIN)"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="cin_no"
                          placeholder="Corporate Identification Number (CIN)"
                          error={touched.cin_no && !!errors.cin_no}
                          helperText={touched.cin_no && errors.cin_no}
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Registered  Email ID of Company"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="company_email"
                          placeholder="Registered  Email ID of Company"
                          error={
                            touched.company_email && !!errors.company_email
                          }
                          helperText={
                            touched.company_email && errors.company_email
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Industry"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="industry"
                          placeholder="Industry"
                          error={touched.industry && !!errors.industry}
                          helperText={touched.industry && errors.industry}
                        />
                      }
                    />

                    <CustomFormGroup
                      formlabel="Company website"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="company_website"
                          placeholder="Company website"
                          error={
                            touched.company_website && !!errors.company_website
                          }
                          helperText={
                            touched.company_website && errors.company_website
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Contact Person for Corporate Debtor"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="contact_person"
                          placeholder="Contact Person for Corporate Debtor"
                          error={
                            touched.contact_person && !!errors.contact_person
                          }
                          helperText={
                            touched.contact_person && errors.contact_person
                          }
                        />
                      }
                    />

                    <CustomFormGroup
                      formlabel="Contact Person Designation"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="contact_designation"
                          placeholder="Contact Person Designation"
                          error={
                            touched.contact_designation &&
                            !!errors.contact_designation
                          }
                          helperText={
                            touched.contact_designation &&
                            errors.contact_designation
                          }
                        />
                      }
                    />
                    <CustomFormGroup
                      formlabel="Contact Person Phone Number"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="contact_number"
                          placeholder="Contact Person Phone Number"
                          error={
                            touched.contact_number && !!errors.contact_number
                          }
                          helperText={
                            touched.contact_number && errors.contact_number
                          }
                        />
                      }
                    />

                    <CustomFormGroup
                      formlabel="Contact Person Email ID"
                      star="*"
                      FormField={
                        <CustomInputField
                          type="text"
                          name="contact_email"
                          placeholder="Contact Person Email ID"
                          error={
                            touched.contact_email && !!errors.contact_email
                          }
                          helperText={
                            touched.contact_email && errors.contact_email
                          }
                        />
                      }
                    />
                  </Row>
                  <Button
                    className="m-2"
                    style={{ float: "right" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <Row>
              {CorporateDebtorInformationArr.map((detail, index) => (
                <Col key={index} md={4} sm={4} className="px-5 py-3">
                  <h4
                    className="m-0"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#6b7280",
                    }}
                  >
                    {detail.title}
                  </h4>
                  <p
                    className="m-0"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333333",
                    }}
                  >
                    {detail.details}
                  </p>
                </Col>
              ))}
            </Row>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Creditor Information
        </AccordionSummary>
        <AccordionDetails>
          <TableCurdHandler
            columns={CreditorInformationColumns}
            fields={CreditorInformationFields}
            initialData={CreditorInformationData.data} // Fetch data from query
            defaultAction={false}
            handleAdd={handleCreditorInformationAdd}
            handleEdit={handleCreditorInformationEdit}
            handleDelete={handleDelete}
            handleView={handleView}
            loading={
              CreditorInformationAddIsLoading ||
              CreditorInformationData.isLoading ||
              CreditorInformationDeletesLoading ||
              CreditorInformationEditIsLoading
            }
            showSubmitButton={false}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Promoter Information
        </AccordionSummary>
        <AccordionDetails>
          <TableCurdHandler
            columns={PromoterInformationColumns}
            fields={PromoterInformationFields}
            initialData={PromoterInformationData.data} // Fetch data from query
            defaultAction={false}
            handleAdd={handlePromoterInformationAdd}
            handleEdit={handlePromoterInformationEdit}
            handleDelete={handleDelete}
            handleView={handleView}
            loading={
              PromoterInformatioAddIsLoading ||
              PromoterInformationData.isLoading ||
              CreditorInformationDeletesLoading ||
              PromoterInformationEditIsLoading
            }
            showSubmitButton={false}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DebtorsProfile;
