import axios from "../services/axios";
import { useOther } from "./Hooks";

const useCIRPStageHook = () => {
  const { useMutation, systemDT } = useOther();

  // Function that makes the POST request
  const setAddCIRPStageHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-stage/add`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };
  // Function that makes the POST request
  const setDeleteCIRPStageHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-stage/delete`, data);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useDeleteCIRPStageHook = () => {
    return useMutation(setDeleteCIRPStageHook); // return the mutation hook
  };
  // Function that makes the POST request
  const setAllCIRPStageHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-stage/all-data`);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useAddCIRPStageHook = () => {
    return useMutation(setAddCIRPStageHook); // return the mutation hook
  };

  return {
    setAllCIRPStageHook,
    useAddCIRPStageHook,
    useDeleteCIRPStageHook,
  };
};

export default useCIRPStageHook;
