const Cirputils = () => {
  //  ============================ Creditor Information ============================
  const CreditorInformationColumns = [
    { label: "Type of Creditors", field: "creditor_type" },
    { label: "Name of Creditor", field: "creditor_name" },
    { label: "Debt (in Rs.)", field: "debt" },
    { label: "% Share of Debt", field: "debt_share" },
    { label: "Email ID", field: "creditor_email" },
    { label: "Phone No.", field: "creditor_phone" },
    { label: "Address", field: "address" },
  ];

  const CreditorInformationFields = [
    { label: "Type of Creditor", field: "creditor_type", type: "text" },
    { label: "Name of Creditor", field: "creditor_name", type: "text" },
    { label: "Debt (in Rs.)", field: "debt", type: "number" },
    { label: "% Share of Debt", field: "debt_share", type: "number" },
    { label: "Email ID", field: "creditor_email", type: "email" },
    { label: "Phone No.", field: "creditor_phone", type: "tel" },
    { label: "Address", field: "address", type: "text" },
  ];

  //  ============================ Promoter Information ============================

  const PromoterInformationColumns = [
    { label: "Name", field: "name" }, // Name field
    { label: "DIN / PAN No", field: "din_pan" }, // DIN/PAN No field
    { label: "Designation", field: "designation" }, // Designation field
    { label: "Email ID", field: "email_id" }, // Email field
    { label: "Phone Number", field: "phone_number" }, // Phone Number field
    { label: "Address", field: "address" }, // Address field
  ];

  const PromoterInformationFields = [
    { label: "Name", field: "name", type: "text" },
    { label: "DIN / PAN No", field: "din_pan", type: "text" },
    { label: "Designation", field: "designation", type: "text" },
    { label: "Email ID", field: "email_id", type: "email" },
    { label: "Phone Number", field: "phone_number", type: "tel" },
    { label: "Address", field: "address", type: "text" },
  ];

  return {
    CreditorInformationColumns,
    CreditorInformationFields,
    PromoterInformationColumns,
    PromoterInformationFields,
  };
};

export default Cirputils;
