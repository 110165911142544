import axios from "../services/axios";
import { useOther } from "./Hooks";
import dayjs from "dayjs";


const DateTime = dayjs().format("YYYY-MM-DD HH:mm:ss");


const useCIRPMilestoneHook = () => {
  const { useMutation, systemDT } = useOther();

  // Function that makes the POST request
  const setAddCIRPMilestoneHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-milestone/add`, {
        ...data,
        systemDT: systemDT, // assuming systemDT is some kind of date or timestamp
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };
  // Function that makes the POST request
  const setDeleteCIRPMilestoneHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-milestone/delete`, data);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useDeleteCIRPMilestoneHook = () => {
    return useMutation(setDeleteCIRPMilestoneHook); // return the mutation hook
  };
  // Function that makes the POST request
  const setAllCIRPMilestoneHook = async (data) => {
    try {
      const response = await axios.post(`/cirp-milestone/all-data`);
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  // Hook that uses mutation to call the POST request function
  const useAddCIRPMilestoneHook = () => {
    return useMutation(setAddCIRPMilestoneHook); // return the mutation hook
  };
  // ====================================================================================
  const getDocument = async (assignmentId) => {
    console.log("Fetching documents for assignment ID:", assignmentId);
    try {
      const response = await axios.post(`/cirp-milestone/get-document`, {
        data: assignmentId,
      });
      return response.data; // returning the response data
    } catch (err) {
      throw new Error(err); // rethrowing the error to be handled in the component
    }
  };

  const setAddAssignmentClaim = async (data) => {
    let values = {
      DateTime: "DateTime", // Adding DateTime
    };

    console.log("Received data in setAddAssignmentClaim:", data); // This should log the data
    try {
      const response = await axios.post(`/cirp-milestone/add-cirp-claim`, {
        ...values,
        ...data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  const getcirpClaim = async (data) => {
    console.log(data);
    try {
      const response = await axios.post(`/cirp-milestone/get-all-cirp-claim`, {
        data,
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  const useSetAddAssignmentClaim = () => {
    return useMutation(setAddAssignmentClaim);
  };

  return {
    setAllCIRPMilestoneHook,
    useAddCIRPMilestoneHook,
    useDeleteCIRPMilestoneHook,
    useSetAddAssignmentClaim,
    getcirpClaim,
    getDocument,
  };
};

export default useCIRPMilestoneHook;
