import React from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";
import "./../styles/Customdatatable.css";

const CustomDatatable = ({
  columns,
  data,
  currentPage,
  totalPages,
  onRowClick,
  onPageChange,
}) => {
  return (
    <div className="custom-datatable-wrapper">
      <table className="custom-datatable">
        <thead>
          <tr>
            <th width={"15px"}></th>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
                <td width={"15px"}>
                  <ChevronRight
                    className="row-chevron"
                    size={16}
                    onClick={() => onRowClick && onRowClick(row)}
                  />
                </td>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.render ? column.render(row) : row[column.key]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>

        {/* <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td width={"15px"}>
                  <ChevronRight
                    className="row-chevron"
                    size={16}
                    onClick={() => onRowClick(row)}
                  />
                </td>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{row[column.key]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + 1} className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody> */}
      </table>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="pagination-footer">
          <div className="pagination-controls">
            <button
              className="pagination-btn"
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            >
              <ChevronLeft size={16} />
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                className={`pagination-btn ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => onPageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="pagination-btn"
              disabled={currentPage === totalPages}
              onClick={() => onPageChange(currentPage + 1)}
            >
              <ChevronRight size={16} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDatatable;
